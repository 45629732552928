@import "styles/mixins";
@import "styles/typography";

.general-info-box {
    p {
        margin-bottom: 16px;
        @include body1;
    }
    img {
        width: 100%;
        border-radius: 8px;
        margin: 16px 0 32px;
    }
    a {
        color: var(--green);
        transition: 0.3s ease-out;
        &:hover {
            color: var(--toxicGreen);
        }
    }
    .button {
        display: inline-flex;
    }

    .button.read-more {
        background-color: transparent;
        color: var(--green);
        height: auto;
        width: auto;
    }
}
