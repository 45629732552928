.youtube-link {
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;
    margin-top: 32px;
    border-radius: 8px;
    overflow: hidden;

    @media screen and (max-width: 1024px) {
        margin-top: 24px;
    }

    @media screen and (max-width: 768px) {
        margin-top: 16px;
    }

    .description-demibold {
        position: absolute;
        z-index: 1;
    }

    &__title {
        position: absolute;
        display: flex;
        align-items: center;
        gap: 16px;
        z-index: 1;
        color: white;
        padding: 32px;

        @media screen and (max-width: 1024px) {
            gap: 12px;
            padding:16px;
        }

        img {
            @media screen and (max-width: 1024px) {
                    width: 40px;
                }
        }

        .description-demibold {
            position: static;
        }
    }

    &__image {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
// UFTM-49        filter: blur(40px);
        top: 0;
        left: 0;
    }

    a {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(1.43);
    }
}
