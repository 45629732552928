@import "styles/mixins";

.with-sidebar-layout {
    display: grid;
    $gap: 8px;

    @function col-width($col) {
        $col-width: calc(100% / 12);
        @return calc(#{$col-width * $col} - $gap / 2);
    }

    grid-template-columns: col-width(4) col-width(8);
    gap: $gap;
    position: relative;
    &__left {
        position: relative;
        z-index: 2;
    }
    &__right {
        display: grid;
        gap: 8px 0;
        align-self: flex-start;
        width: 100%;
        position: relative;
        z-index: 1;
    }
    &.xl {
        @include w($xl) {
            grid-template-columns: 1fr;
            &.mobile-reverse {
                display: flex;
                flex-direction: column-reverse;
            }
        }
    }
    &.lg {
        @include w($lg) {
            grid-template-columns: 1fr;
            &.mobile-reverse {
                display: flex;
                flex-direction: column-reverse;
            }
        }
    }
}
