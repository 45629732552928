@import "styles/typography";
@import "styles/mixins";

main.lang-en {
    .discover-title,
    .discover-text {
        max-width: 380px;
        @include w($md) {
            max-width: none;
        }
    }
}

.trial-results {
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, rgba(25, 99, 107, 0) 40%, rgba(0, 255, 0, 0.7) 100%);
    background-color: var(--black);
    padding: 32px;
    border-radius: 16px;

    .gradient-text {
        max-width: 700px;
        margin-bottom: 64px;
    }

    @include w($lg) {
        padding: 16px;
        .gradient-text {
            margin-bottom: 32px;
        }
    }
    @include w($md) {
        background: linear-gradient(180deg, rgba(25, 99, 107, 0) 60%, rgba(0, 255, 0, 0.7) 100%);
        background-color: var(--black);
    }
}

.discover {
    display: flex;
    margin-bottom: 25px;
    height: 590px;
    position: relative;
    @include w(1620px) {
        height: 36.8vw;
    }

    &-item {
        padding: 32px;
        background-repeat: no-repeat;
    }

    .discover-title {
        margin-bottom: 16px;
        @include h4;
    }
    .discover-text {
        @include body1;
        margin-bottom: 32px;
        opacity: 0.8;
    }

    &-1 {
        display: flex;
        padding: 32px;
        background-size: cover;

        @include min-w($md) {
            flex-direction: column;
            background-image: url("../../../images/brick-1.svg");
            aspect-ratio: 562 / 528;
            &-content {
                margin-top: auto;
                padding-top: 32px;
            }
            img {
                width: 54%;
            }
        }
        @include w($md) {
            aspect-ratio: 342 / 386;
            background-image: url("../../../images/brick-1-mob.svg");
            flex-direction: column-reverse;
            img {
                margin-top: auto;
                width: 62%;
            }
        }
        @include for-safari-and-firefox {
            @include min-w($md) {
                &-content {
                    margin-top: auto;
                    padding-top: 0 !important;
                }
                transform: none;
                left: 0;
                top: 0;
                bottom: 0;
                width: 41.6%;
                position: absolute;
            }
            @include w($md) {
                flex-direction: column-reverse;
                aspect-ratio: auto;
                background-size: contain;
                height: 100vw;
            }
        }
    }
    &-2 {
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
        mask-position: center;
        -webkit-mask-size: 100%;
        height: 100%;
        background-size: cover;

        @include min-w($md) {
            aspect-ratio: 562 / 528;
            transform: translateX(-39%);
            mask-image: url("../../../images/mask-trial-res.svg");
        }
        @include w($md) {
            aspect-ratio: 342 / 386;
            mask-image: url("../../../images/brick-2-mob.svg");
            margin-top: -54%;
            display: flex;
            flex-direction: column;
            .discover-title {
                margin-top: auto;
            }
        }

        @include w($mobile) {
            position: relative;
            
            .discover-title {
                margin-bottom: 5px;
            }
            .discover-text.discover-text {
                margin-bottom: 5px !important;
            }
            .button {
                position: absolute;
                bottom: 10px;
                right: 10px;
            }
        }
        @include w($mobile-small) {
            .discover-text.discover-text {
                margin-bottom: 0 !important;
            }
            .button {
                position: absolute;
                bottom: 5px;
                right: 10px;
            }
            .discover-title.discover-title {
                margin-bottom: 0;
            }
        }

        @include for-safari-and-firefox {
            @include min-w($md) {
                aspect-ratio: auto;
                transform: none;
                top: 0;
                bottom: 0;
                left: 25.4%;
                width: 41%;
                position: absolute;
                mask-position: 0 0;
                mask-size: cover;
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
            }
            @include w($md) {
                aspect-ratio: auto;
                margin-top: -48.5vw;
                height: 100vw;
            }
        }
    }
    &-3 {
        display: flex;
        flex-direction: column;
        background-size: cover;
        .discover-title {
            margin-top: auto;
        }
        @include min-w($md) {
            background-image: url("../../../images/brick-3.svg");
            aspect-ratio: 448 / 528;
            transform: translateX(-47%);
        }
        @include w($md) {
            margin-top: 10px;
            background-image: url("../../../images/brick-3-mob.svg");
            aspect-ratio: 342/400;
        }
        @include for-safari-and-firefox {
            @include min-w($md) {
                transform: none;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                width: 33.1%;
            }
            @include w($md) {
                background-size: contain;
                aspect-ratio: auto;
                height: 104vw;
            }
        }
    }
    @media screen and (max-width: 1366px) and (min-width: 768px) {
        .discover-1-content {
            padding-top: 2vw;
        }
        .discover-title {
            font-size: 2.3vw;
        }
        .discover-text {
            font-size: 1.3vw;
            margin-bottom: 2vw;
        }
    }
    @include w($lg) {
        &-item {
            padding: 16px;
        }
    }
    @include w($md) {
        flex-direction: column;
        height: auto;
        margin-bottom: 0;
        .discover-title {
            margin-bottom: 12px;
            font-size: 5.7vw;
        }
        .discover-text {
            margin-bottom: 24px;
        }
    }
}
