@import "styles/mixins";


.react-parallax-bgimage {
    object-fit: cover !important;
    
    @media screen and (max-width: 768px) {
        object-fit: none !important;
    }
}
.primary-section-wrapper {
    position: relative;
    border-radius: 16px;
    height: 400px;
    overflow: hidden;
    .banner-bg {
        position: absolute;
        inset: 0;
        .react-parallax {
            height: 100%;
        }
    }
    // .bg {
    //     position: absolute;
    //     bottom: 0;
    //     transform: scale(1.3);
    // }
    .banner-title {
        opacity: 0;
        transform: translateY(10px);
        animation: banner-title-show 0.4s forwards;
        @keyframes banner-title-show {
            to {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }


    .primary-section {
        position: relative;
        z-index: 1;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 80px 8px 32px 32px;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(9, 20, 21, 0) 0%, #091415 100%);

        .left-side {
            max-width: 986px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            &__content {
                display: grid;
                justify-items: flex-start;
                gap: 16px 0;
            }

            .breadcrumbs {
                margin-left: -24px;

                @include w($lg) {
                    margin-left: -8px;
                }
            }
        }

        .button-wrapper {
            position: relative;
            max-width: 366px;
            width: 100%;
            margin-right: -8px;
            margin-bottom: -32px;
            border-top: 8px solid var(--white);
            border-left: 8px solid var(--white);
            background-color: var(--white);
            border-top-left-radius: 16px;

            @media screen and (max-width: 1024px) {
                display: none;
            }

            .smooth-corner1,
            .smooth-corner2 {
                position: absolute;
                width: 40px;
                height: 40px;
                background-color: transparent;
                border-right: 8px solid var(--white);
                border-bottom: 8px solid var(--white);
                border-bottom-right-radius: 16px;
                bottom: -8px;
                left: -40px;
            }

            .smooth-corner2 {
                bottom: 64px;
                left: auto;
                right: -8px;
            }
        }
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @include w($lg) {
        height: auto;
        .primary-section {
            height: 320px;
            padding: 72px 8px 16px 16px;
            .breadcrumbs {
                margin-bottom: 56px;
            }
        }
    }
}

.banner-bottom {
    margin-top: 8px;
    display: none;

    @media screen and (max-width: 1024px) {
        display: flex;
    }
}
