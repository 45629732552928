@import "styles/mixins";

.distribution-contacts {
    display: flex;
    gap: 8px;
    background-color: var(--black);
    padding: 32px;
    border-radius: 16px;
    min-height: 700px;

    @include w($lg) {
        flex-direction: column;
        padding: 16px;
        gap: 16px;
    }

    &__contacts-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
        &__contacts {
            background-color: var(--white-secondary-12);
            padding: 24px;
            border-radius: 8px;

            @include w($lg) {
                padding: 16px;
            }

            p {
                margin-bottom: 32px;

                @include w($lg) {
                    margin-bottom: 24px;
                }
            }
            .dropdowns {
                display: flex;
                flex-direction: column;
                gap: 32px;
            }
        }

        &__distributor {
            background-color: var(--white-secondary-12);
            padding: 24px;
            border-radius: 8px;
            flex: 1;

            @include w($lg) {
                padding: 16px;
            }

            & > img {
                width: 106px;
                border-radius: 50%;
                margin-bottom: 24px;

                @include w($lg) {
                    width: 80px;
                    margin-bottom: 16px;
                }
            }

            & .description-demibold {
                margin-bottom: 16px;

                @include w($lg) {
                    margin-bottom: 12px;
                }
            }

            & .body1 {
                opacity: 0.8;
                margin-bottom: 24px;

                @include w($lg) {
                    margin-bottom: 16px;
                }
            }
        }
    }

    & > img {
        border-radius: 8px;
        max-width: 68%;

        @include w($lg) {
            max-width: none;
        }
    }

    &__contact {
        color: var(--toxicGreen);
        display: block;
    }

    .distributor-phone {
        display: inline-block;
        position: relative;
        margin-bottom: 16px;

        @include w($lg) {
            margin-bottom: 12px;
        }
    }

    .distributor-email {
        position: relative;
    }

    .distributor-phone:before,
    .distributor-phone:after,
    .distributor-email:before,
    .distributor-email:after {
        display: inline-block;
        content: "";
        width: 24px;
        height: 24px;
        transition: all 0.3s;
    }

    .distributor-phone:before {
        position: relative;
        bottom: -6px;
        right: 4px;
        background: url("../../images/icons/phone.svg");
    }

    .distributor-email:before {
        position: relative;
        bottom: -6px;
        right: 4px;
        background: url("../../images/icons/mail.svg");
    }

    .distributor-phone:after {
        opacity: 0;
        position: absolute;
        bottom: -4px;
        left: -4px;
        background: url("../../images/icons/greenPhone.svg");
    }

    .distributor-email:after {
        opacity: 0;
        position: absolute;
        bottom: -4px;
        left: -4px;
        background: url("../../images/icons/greenMail.svg");
    }

    .distributor-phone:hover::before,
    .distributor-email:hover::before {
        opacity: 0;
    }

    .distributor-phone:hover::after,
    .distributor-email:hover::after {
        opacity: 1;
    }
}
