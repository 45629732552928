@import "styles/mixins";
@import "styles/typography";

.mission-values-section {
    position: relative;
}

.mission-section {
    height: calc(100dvh - 16px);
    background-color: var(--black);
    position: relative;
    overflow: hidden;
    border-radius: 16px;
    margin-bottom: 8px;

    &__gradient {
        position: absolute;
        width: 100%;
        height: 80%;
        bottom: 0;
        background: var(--greenLinearGradient);
        z-index: 2;
    }
    &__mask {
        z-index: 3;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
        mask-position: center;
        -webkit-mask-size: 100%;
        width: 100%;
        height: 100%;

        img {
            aspect-ratio: 1/1;
            position: absolute;
            top: 50%;
            width: 100%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 100%;
            object-fit: cover;
            @include w($lg) {
                width: calc(100% - 16px);
            }
        }
    }
    &__title {
        z-index: 3;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 120px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.05em;
        width: 100%;
        text-align: center;
        padding: 0 32px;
        max-width: 1170px;
    }
    @include w($xl) {
        &__title {
            font-size: 90px;
        }
    }
    @include w($lg) {
        min-height: 640px;
        &__title {
            padding: 0 16px;
            font-size: 70px;
        }
    }
    @include w($md) {
        &__title {
            font-size: 48px;
        }
    }
}

.values-section {
    position: relative;
    padding: 82px 32px 32px;
    // border-radius: 16px;
    // border: 2px solid red;
    // background: rgba(red, 0.5);
    z-index: 4;
    height: 100%;
    // overflow: hidden;
    @include w($lg) {
        padding: 16px;
    }
}

.values-card-wrapper {
    width: 50%;
    position: relative;
    &:nth-child(odd) {
        margin-left: auto;
    }

    &:nth-child(1) {
        @include angle-position(3, bottom-left);
    }
    &:nth-child(2) {
        @include angle-position(2, bottom-right);
    }
    &:nth-child(3) {
        .angle-decor {
            display: none;
        }
    }
    @include w($lg) {
        width: 100%;
        margin-bottom: 24px;
        .angle-decor {
            display: none;
        }
    }
    .angle-decor {
        display: none;
    }
}

.values-card {
    border-radius: 8px;
    min-height: 200px;
    padding: 24px;
    backdrop-filter: blur(30px);
    background-color: var(--white-secondary-12);
    transform: translate3d(0, 0, 0);
    &__title {
        @include h4;
        margin-bottom: 12px;
    }
    &__text {
        @include description-medium;
    }
    @include w($lg) {
        padding: 16px 16px 20px;
        min-height: auto;
    }
}
