.sitemap{
    padding-top: 30px;
    padding-bottom: 30px;
}
.sitemap__link {
    color: var(--black);
    font-size: 20px;
    text-transform: uppercase;
}

.sitemap__link:hover {
    color: var(--green);
    transition: all ease 0.5s;
}